import React from 'react';
import "../styles/Contact.css";

function Contact() {
  return (
    <div className="contact">
        <div className="rightSide">
            <h1>Contact Us</h1>

            <h2>Email</h2>
            <p>pixelwizardstudiosdenver@gmail.com</p>

            <h2>Phone:</h2>
            <p>+1 720-434-6807</p>

        </div>
      
    </div>
  )
}

export default Contact
